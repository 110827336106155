<template>
  <!--begin::Login-->
  <div class="login-form">
    <div class="login-title">
      {{ $t("AUTH.LOGIN.PRIVATE_AREA") }}
      <hr class="login-title-sub" />
    </div>

    <v-form
      v-if="!formChoose.show"
      class="form"
      ref="formLogin"
      v-model="formLogin.valid"
      lazy-validation
    >
      <div class="form-group">
        <label>
          {{ $t("MY_PROFILE.EMAIL") }}
        </label>
        <v-text-field
          class="form-control"
          v-model="formLogin.email"
          :placeholder="$t('MY_PROFILE.EMAIL')"
          autocomplete="off"
          :rules="[rules.required, rules.email]"
          required
        >
          <template v-slot:prepend-inner>
            <inline-svg src="/media/icons/contact-gray.svg" />
          </template>
        </v-text-field>
      </div>

      <div class="form-group">
        <label>
          {{ $t("MY_PROFILE.PASSWORD") }}
        </label>
        <v-text-field
          class="form-control"
          type="password"
          v-model="formLogin.password"
          :placeholder="$t('MY_PROFILE.PASSWORD')"
          autocomplete="off"
          :rules="[rules.required]"
          required
        >
          <template v-slot:prepend-inner>
            <inline-svg src="/media/icons/lock-alt-1-lock-gray.svg" />
          </template>
        </v-text-field>
      </div>

      <div class="my-3 text-center">
        <button
          ref="formLoginSubmit"
          class="btn btn-pill btn-tertiary"
          :disabled="!formLogin.valid"
          @click="onSubmitLogin"
        >
          {{ $t("AUTH.LOGIN.DO_LOGIN") }}
        </button>
      </div>

      <div class="text-center">
        <router-link to="/forgotpassword">
          <u class="black">{{ $t("AUTH.LOGIN.FORGOT_YOUR_PASSWORD") }}</u>
        </router-link>
      </div>

      <div v-for="i in [2, 3]" :key="'Register_' + i" class="my-4 text-center">
        <p class="black">{{ $t("REGISTER.QUESTION." + i) }}</p>
        <router-link :to="'/register/' + i">
          <button
            class="btn btn-pill btn-white button-form button-form-primary px-6"
          >
            {{ $t("REGISTER.BUTTON." + i) }}
          </button>
        </router-link>
      </div>
    </v-form>

    <v-form
      v-if="formChoose.show"
      class="form"
      ref="formChoose"
      v-model="formChoose.valid"
      lazy-validation
    >
      <div class="form-group mb-0">
        <label>{{ $t("AUTH.LOGIN.SELECT_YOUR_COMPANY") }}:</label>
        <v-radio-group v-model="formChoose.choosenCompanyID">
          <v-radio
            v-for="c in formChoose.chooseCompanyList"
            :key="c.CompanyID"
            :value="c.CompanyID"
            mandatory
          >
            <template v-slot:label>
              <div class="pt-3">
                <span class="font-size-h3 text-tertiary ml-3">
                  {{ c.CompanyName }}
                </span>
                ({{ $t("ROLE_NAMES_BY_ID." + c.RoleID) }})
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>

      <div class="py-3 text-center">
        <button
          ref="formChooseSubmit"
          class="btn btn-pill btn-tertiary"
          :disabled="!formChoose.valid"
          @click="onSubmitChoose"
        >
          {{ $t("AUTH.LOGIN.DO_LOGIN") }}
        </button>
      </div>
    </v-form>
  </div>
  <!--end::Login-->
</template>

<script>
import { mapGetters } from "vuex";
import { DO_AUTH, CHANGE_COMPANY } from "@/core/services/store/auth.module";
import LoginService from "@/core/services/api/v2/login.service";

export default {
  name: "Login",
  data() {
    return {
      formLogin: {
        valid: true,
        email: "",
        password: ""
      },
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        email: value =>
          !!/.+@.+\..+/.test(value) || this.$i18n.t("VALIDATION.INVALID_EMAIL")
      },
      formChoose: {
        show: false,
        valid: true,
        chooseCompanyList: [],
        chosenCompanyID: 0
      }
    };
  },
  computed: {
    ...mapGetters(["rolesList"])
  },
  mounted() {
    // Set parent layout content
    this.$emit(
      "layout-login-aside-background",
      process.env.BASE_URL + "media/bg/imagen-bienvenido.jpg"
    );
    this.$emit(
      "layout-login-aside-title",
      this.$i18n.t("AUTH.LOGIN.ASIDE_TITLE")
    );
    this.$emit(
      "layout-login-aside-description",
      this.$i18n.t("AUTH.LOGIN.ASIDE_DESCRIPTION")
    );

    this.$nextTick(() => {
      document.title =
        this.$t("HEADER.TITLE") + " " + this.$i18n.t("AUTH.LOGIN.ASIDE_TITLE");
    });
  },
  methods: {
    onSubmitLogin(e) {
      e.preventDefault();

      if (this.$refs.formLogin.validate()) {
        // Disable submit button
        this.formLogin.valid = false;

        // Send request
        LoginService.login(this.formLogin.email, this.formLogin.password).then(
          rawResponse => {
            if (typeof rawResponse === "object") {
              // Login OK, got user's data
              this.$store.dispatch(DO_AUTH, rawResponse);

              // If he has just one company, continue; else choose one
              let rawCompanies = rawResponse.Companies;
              if (rawCompanies.length === 1) {
                this.$store.dispatch(CHANGE_COMPANY, rawCompanies[0]);

                this.$router.push("/manager/home").catch(() => {
                  // To avoid some ugly messages about redirect
                  // console.info(error.message);
                });
              } else {
                this.formChoose.show = true;
                this.formChoose.chooseCompanyList = rawCompanies;
                this.formChoose.choosenCompanyID = rawCompanies[0].CompanyID;
              }
            } else {
              this.$bvToast.toast(
                this.$i18n.t("AUTH.LOGIN.WRONG_CREDENTIALS"),
                {
                  title: this.$i18n.t("GENERIC.ERROR"),
                  variant: "danger",
                  noCloseButton: true,
                  solid: true
                }
              );

              this.formChoose.show = false;
              this.formLogin.valid = true;
              this.formLogin.password = "";
            }
          }
        );
      }
    },
    onSubmitChoose(e) {
      e.preventDefault();

      // Disable submit button
      this.formChoose.valid = false;

      // Send request
      LoginService.login2(this.formChoose.choosenCompanyID).then(
        responseCode => {
          if (responseCode < 400) {
            for (let c = 0; c < this.formChoose.chooseCompanyList.length; c++) {
              let maybeCompany = this.formChoose.chooseCompanyList[c];

              if (this.formChoose.choosenCompanyID === maybeCompany.CompanyID) {
                this.$store.dispatch(CHANGE_COMPANY, maybeCompany);
                break;
              }
            }

            this.$router.push("/manager/home").catch(() => {
              // To avoid some ugly messages about redirect
              // console.info(error.message);
            });
          } else {
            this.$bvToast.toast(this.$i18n.t("AUTH.LOGIN.WRONG_CREDENTIALS"), {
              title: this.$i18n.t("GENERIC.ERROR"),
              variant: "danger",
              noCloseButton: true,
              solid: true
            });

            this.formChoose.show = false;
            this.formChoose.chooseCompanyList = [];
            this.formChoose.chosenCompanyID = 0;
            this.formLogin.email = "";
            this.formLogin.password = "";
            this.formLogin.valid = true;
          }
        }
      );
    }
  }
};
</script>
